import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'

const ImageLG = 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-menor-2/image.webp'
const ImageTablet = 'https://central-imagens.bancointer.com.br/images-without-small-versions/768-menor/image.webp'
const ImageXL = 'https://central-imagens.bancointer.com.br/images-without-small-versions/conta-menor/image.webp'

export const Image = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: ${grayscale[200]};

  @media ${device.tablet} {
    height: 480px;
    background-image: url(${ImageTablet});
    background-repeat: no-repeat;
    background-size: contain;
    border-bottom-left-radius: 168px;
  }

  @media ${device.desktopLG} {
    height: 100vh;
    background-image: url(${ImageLG});
    position: absolute;
    background-size: contain;
    width: 50%;
    border-top-right-radius: 168px;
    border-bottom-left-radius: 0;
  }

  @media ${device.desktopXL} {
    background-image: url(${ImageXL});
    background-size: contain;
    background-color: white;
  }
`
export const Wrapper = styled.section`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  overflow: hidden;
  padding: 30px;

  @media ${device.tablet} {
    min-height: auto;
    padding: 0;
  }

  @media ${device.desktopLG} {
    min-height: 100vh;
  }

  @media ${device.desktopXXXL} {
    justify-content: center;
  }

  input::placeholder {
    font-weight: 700;
    color: ${grayscale[300]};
  }
`
export const Container = styled.div`
  overflow: hidden;
  width: 100%;
  position:  relative;

  @media ${device.desktopLG} {
    min-height: 100vh;
  }

`
